import { Form } from 'antd';
import React from 'react';
import { useWatch } from 'antd/es/form/Form';
import { baseAuthorBindingMapper, mapCities, rolesMapper } from 'client/mappers/author';
import useAsyncSelectOptions from 'hooks/useAsyncSelectOptions';
import { AdminAuthorRolesListResponseDto, RolesResponseDto } from 'models';
import { BASE_ROLES_SEARCH_URL } from 'utils';
import { BASE_AUTHORS_SEARCH_URL } from 'utils/constants';
import { getCitiesEndpointWithSearchUrl } from 'client/endpoints/cities';
import { DebouncedSelect } from 'components/DebouncedSelect';
import { useSocialRolesList } from 'client/hooks/socialRoles';
import { useAuthors } from 'client/hooks/authors';
import { useCitiesEntity } from 'client/hooks/cities';
import { VideoItemConnectionsFormValues } from 'pages/Video/types';
import { useVideoTags } from 'client/hooks/videos/useVideoTags';
import { Index } from 'client/endpoints';
import { mapDadataResponse } from 'utils/formatters/mapDadataResponse';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type VideoConnectionsFormProps = { initialValues: VideoItemConnectionsFormValues; isActiveForm?: boolean };

/**
 *
 * @param isActiveForm - Определяет, является ли текущая форма активной (открыт ли таб, в котором она находится)
 */

export const VideoConnectionsForm: React.FC<VideoConnectionsFormProps> = ({ initialValues, isActiveForm = true }) => {
  const [form] = Form.useForm<VideoItemConnectionsFormValues>();

  const { roles } = useSocialRolesList(isActiveForm);
  const { data: videoTags } = useVideoTags(isActiveForm);
  const { data: cities } = useCitiesEntity(isActiveForm);
  const { data: authors } = useAuthors(isActiveForm);

  const { fetcher: rolesFetcher } = useAsyncSelectOptions<RolesResponseDto>(BASE_ROLES_SEARCH_URL, rolesMapper);

  const { fetcher: tagsFetcher } = useAsyncSelectOptions<RolesResponseDto>(
    Index.videosAdmin + '/tags/search?limit=20&query=',
    mapDadataResponse,
  );

  const { fetcher: authorsFetcher } = useAsyncSelectOptions<AdminAuthorRolesListResponseDto>(
    BASE_AUTHORS_SEARCH_URL,
    baseAuthorBindingMapper,
  );

  const { fetcher: citiesFetcher } = useAsyncSelectOptions(getCitiesEndpointWithSearchUrl(), mapCities);

  const mappedRoles = rolesMapper(roles);

  const mappedTags = (videoTags || []).map((tag) => {
    return {
      label: tag.name,
      key: tag.id,
      value: tag.name,
    };
  });

  const mappedAuthors = baseAuthorBindingMapper(authors);

  const mappedCities = mapCities(cities);

  const selectedAreas = useWatch('areasIds', form);

  return (
    <Form form={form} initialValues={initialValues} {...layout} name="connections">
      <Form.Item name="areasNames" label="Город" rules={[{ required: true }]}>
        <DebouncedSelect
          fetcher={citiesFetcher}
          mode="multiple"
          initialOptions={mappedCities}
          onSelect={(_, option) => {
            form.setFieldValue('areasIds', [...selectedAreas, option.key]);
          }}
          onDeselect={(option) => {
            const foundItem = initialValues?.areasNames?.find((item) => item.label === option);

            if (foundItem)
              form.setFieldValue(
                'areasIds',
                selectedAreas.filter((item) => item !== foundItem.key),
              );
          }}
        />
      </Form.Item>
      <Form.Item name="areasIds" label="Города" style={{ display: 'none' }} />
      <Form.Item name="rolesIds" label="Связанные роли" rules={[{ required: true }]}>
        <DebouncedSelect fetcher={rolesFetcher} initialOptions={mappedRoles} labelInValue mode="multiple" />
      </Form.Item>
      <Form.Item name="tagsIds" label="Связанные теги" rules={[{ required: true }]}>
        <DebouncedSelect fetcher={tagsFetcher} initialOptions={mappedTags} labelInValue mode="multiple" />
      </Form.Item>
      <Form.Item name="authorId" label="Связанный автор">
        <DebouncedSelect fetcher={authorsFetcher} initialOptions={mappedAuthors} labelInValue allowClear />
      </Form.Item>
    </Form>
  );
};
