import { notification } from 'antd';
import useAxios from 'axios-hooks';
import { deleteNewEndpointUrl } from 'client/endpoints/news';
import {
  getVideoByIdEndpointUrl,
  getVideoTagsByIdEndpointUrl,
  updateVideoAreasEndpointUrl,
  updateVideoBaseInfoEndpointUrl,
  updateVideoRolesEndpointUrl,
  updateVideoTagsEndpointUrl,
} from 'client/endpoints/videos';
import { mapBaseVideoInfoForBackend, mapRolesVideoInfoForBackend } from 'client/mappers/video';
import { mapTagsVideoInfoForBackend } from 'client/mappers/video/video';
import { AdminGetOneResponse, AdminVideoTagsListResponse } from 'models';
import { VideoItemFormValues } from 'pages/Video/types';
import { NAVIGATION } from 'paths';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  id?: string;
};

export const useVideosBackendActions = ({ id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data: videoData, error: videoError, loading: videoLoading }] = useAxios<BaseResponse<AdminGetOneResponse>>(
    getVideoByIdEndpointUrl(id),
    {
      manual: false,
    },
  );

  const [{ data: tagsData, error: tagsError, loading: tagsLoading }] = useAxios<
    BaseResponse<AdminVideoTagsListResponse[]>
  >(getVideoTagsByIdEndpointUrl(id), {
    manual: false,
  });

  const deleteNew = useAxios(deleteNewEndpointUrl(id), { manual: true })[1];

  const updateBaseVideoData = useAxios(updateVideoBaseInfoEndpointUrl(id), { manual: true })[1];
  const updateRolesVideoData = useAxios(updateVideoRolesEndpointUrl(id), { manual: true })[1];
  const updateAreasVideoData = useAxios(updateVideoAreasEndpointUrl(id), { manual: true })[1];
  const updateTagsVideoData = useAxios(updateVideoTagsEndpointUrl(id), { manual: true })[1];

  const handleDeleteVideo = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteNew();
      notification.success({
        message: `Видео "${videoData?.data.title}" удалено`,
      });
      navigate(NAVIGATION.video);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deleteNew, navigate, videoData?.data.title]);

  const onSave = useCallback(
    async (formFields: VideoItemFormValues) => {
      try {
        setIsLoading(true);
        const mappedFormFields = mapBaseVideoInfoForBackend(formFields);
        const mappedRolesFormFields = mapRolesVideoInfoForBackend(formFields);
        const mappedTagsFormFields = mapTagsVideoInfoForBackend(formFields);

        await updateBaseVideoData({ data: mappedFormFields });

        await updateRolesVideoData({ data: mappedRolesFormFields });

        await updateAreasVideoData({ data: { areasIds: formFields.areasIds } });

        await updateTagsVideoData({ data: mappedTagsFormFields });

        notification.success({ message: 'Видео обновлено' });
        navigate(NAVIGATION.video);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        notification.error({ message: 'Извините, при сохранении видео произошла ошибка' });
      }
    },
    [navigate, updateBaseVideoData, updateRolesVideoData, updateAreasVideoData, updateTagsVideoData],
  );

  return useMemo(
    () => ({
      info: {
        videoError,
        tagsError,
        tagsLoading,
        videoLoading,
        isLoading,
        videoData: videoData?.data,
        tags: tagsData?.data,
      },
      actions: {
        handleDelete: handleDeleteVideo,
        onSave,
      },
    }),
    [videoData, videoError, handleDeleteVideo, isLoading, videoLoading, tagsData, tagsError, tagsLoading, onSave],
  );
};
