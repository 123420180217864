import { notification, Spin } from 'antd';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AdminEventsGetEventAreasResponseDto,
  AdminGetRolesAndNeedsForEventResponse,
  EventResponseDto,
} from '../../models';
import { Index } from '../../client/endpoints';
import { EventContent } from './EventContent';
import useAxios from 'axios-hooks';
import { NAVIGATION } from '../../paths';
import { EventData, EventFormValues } from './Event.types';
import { ClientErrorException } from '../../components/ClientErrorException';
import { getMergedSocials } from '../../socialUrls';
import {
  clientFrontendEventToServer,
  getEventBaseFormInitialValuesForCreationMode,
  getFrontendEvent,
  initialDatesAndTickets,
} from 'client/mappers/event';
import { AxiosError } from 'axios';
import { linkCitiesToEventMapper } from 'client/mappers/event/event';
import { axios } from 'client';

export const Event = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const isCreationMode = id === 'new';
  const [searchParams] = useSearchParams();
  // eventStatus необходим для того, чтобы удостовериться в том, что пользователь создаёт страницу через интерфейс, а не прямой переход по /new.
  const eventStatus = searchParams.get('status');

  const [{ data: rolesData, loading: rolesLoading }] = useAxios<BaseResponse<AdminGetRolesAndNeedsForEventResponse[]>>(
    { url: `${Index.adminEvents}/${id}/roles`, method: 'GET' },
    { manual: isCreationMode },
  );

  const [{ data: areasData, loading: areasLoading }] = useAxios<BaseResponse<AdminEventsGetEventAreasResponseDto>>(
    { url: `${Index.adminEvents}/${id}/areas?limit=${20}`, method: 'GET' },
    { manual: isCreationMode },
  );

  const deleteEvent = useAxios<BaseResponse<EventResponseDto>>(
    { url: `${Index.adminEvents}/${id}`, method: 'DELETE' },
    { manual: true },
  )[1];

  const createEvent = useAxios<BaseResponse<EventResponseDto>>(
    { url: `${Index.adminEvents}`, method: 'POST' },
    { manual: true },
  )[1];
  const editEvent = useAxios<void>({ url: `${Index.adminEvents}/${id}`, method: 'PATCH' }, { manual: true })[1];

  const linkCitiesToEvent = async (data: ReturnType<typeof linkCitiesToEventMapper>, id?: string) => {
    if (!id) {
      return;
    }

    await axios.patch(`${Index.adminEvents}/${id}/areas`, data);
  };

  const [{ data: eventData, loading: isEventLoading, error }] = useAxios<
    BaseResponse<EventResponseDto & { externalId: string | null }>
  >(
    {
      url: `${Index.adminEvents}/${id}`,
      method: 'GET',
    },
    { manual: isCreationMode },
  );

  const archivePlace = async () => {
    try {
      await deleteEvent();
      notification.success({
        message: `Событие  удалено`,
      });
      navigate(NAVIGATION.events);
    } catch (e) {
      notification.error({ message: `Извините, произошла ошибка` });
    } finally {
      /* empty */
    }
  };

  const handleResponseError = (e: unknown) => {
    if (e instanceof AxiosError) {
      if (e?.response?.data?.data?.error?.includes('schedule for event')) {
        notification.error({
          message: `Необходимо заполнить расписание`,
        });
      } else if (e.response?.data.data.detail.includes('Area not found')) {
        notification.error({
          message: 'Выбранный город отсутствует в системе',
        });
      } else if (
        Array.isArray(e.response?.data.data.detail) &&
        e.response?.data.data.detail.includes(
          'type must be one of the following values: Выставка, Кинопоказ, Фестиваль, Квест, Шоу, Театральная постановка, Концерт, Стендап, Экскурсия, Творческая встреча, Форумы и конференции, Лекция, Мастер-класс, Олимпиады и конкурсы, Волонтёрское движение, Благотворительные встречи, Спортивные мероприятия',
        )
      ) {
        notification.error({
          message: 'Указан неверный тип события',
        });
      } else {
        notification.error({
          message: `Произошла ошибка при ${isCreationMode ? 'создании' : 'изменении'}`,
        });
      }
    } else {
      notification.error({
        message: `Произошла непредвиденная ошибка`,
      });
    }
  };

  const onFormFinish = async (values: EventFormValues) => {
    const data = clientFrontendEventToServer(values);
    const linkCities = linkCitiesToEventMapper(values.area, values.linkAllAreas);
    let eventId = id;

    if (values.eventType?.length < 1) {
      return notification.error({
        message: `У мероприятия должен быть указан его тип`,
      });
    }

    try {
      if (isCreationMode) {
        // Чтобы привзять города в режиме создания события сначала создаем событие и получаем его id, после этого привязываем города по id
        const { data: eventData } = await createEvent({ data });
        eventId = eventData.data.id.toString();
      } else {
        await editEvent({
          data: { ...data, externalId: eventData?.data.externalId ?? undefined },
        });
      }

      await linkCitiesToEvent(linkCities, eventId);

      notification.success({
        message: `Событие успешно ${isCreationMode ? 'создано' : 'изменено'}`,
      });
      navigate('/events');
    } catch (e) {
      handleResponseError(e);
    }
  };

  const mappedServerEventData = useMemo((): EventData => {
    const data = eventData?.data;
    const isIdNew = id === undefined || id === 'new';
    if (data === undefined || isIdNew) {
      return {
        baseInfo: getEventBaseFormInitialValuesForCreationMode(),
        datesAndTickets: initialDatesAndTickets,
        id: id ?? 'new',
        image: null,
        gallery: [],
        tickets: {
          ticketsMinPrice: undefined,
          ticketsUrl: '',
        },
        contacts: {
          socials: getMergedSocials([]),
          legalAddress: '',
          sites: [],
          phones: [],
          emails: [],
        },
        needs: [],
        infoForSite: {
          showVideoInNewWindow: true,
        },
      };
    }
    return getFrontendEvent(data, id, rolesData?.data, areasData?.data);
  }, [eventData, id, rolesData, areasData?.data]);

  useEffect(() => {
    if (eventStatus === null && isCreationMode) {
      navigate('/events');
      notification.info({ message: 'Для создания нового события, нажмите кнопку создать' });
    }
  }, [eventStatus, isCreationMode, navigate]);

  const onEventCancel = (isCanceled: boolean) => {
    navigate({ pathname: `/events/${id}`, search: `canceled=${isCanceled}` });
    notification.info({
      message: `Для изменения статуса события нажмите кнопку "Сохранить"`,
    });
  };

  if (error) {
    return <ClientErrorException errorCode={error.request.status} />;
  }

  if (isEventLoading || rolesLoading || areasLoading) {
    return (
      <div className="d-flex mt-32 flex-justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <EventContent
      onArchive={archivePlace}
      onSubmit={onFormFinish}
      event={mappedServerEventData}
      onCancel={onEventCancel}
    />
  );
};
