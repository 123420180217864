import useAxios from 'axios-hooks';
import { useCallback, useMemo, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getCity } from 'client/mappers/city';
import { AdminCitiesAreasResponseDto } from 'models';
import {
  cityByIdEndpointUrl,
  createCityEndpointUrl,
  deleteCityEndpointUrl,
  updateCityEndpointUrl,
} from 'client/endpoints/cities';
import { NAVIGATION } from 'paths';
import { DefaultAxiosError } from 'utils/types';
import { RcFile } from 'antd/es/upload';
import { axios } from 'client/axios';
import { ImageEntity } from 'hooks/useUploadImage';

type Props = {
  isCreationMode: boolean;
  id?: string;
};

interface MappedErrors {
  [key: string]: string;
}

const errorMessagesMapper: MappedErrors = {
  'Area already exist': 'Такой город уже существует',
  'You need to delete region as an event venue':
    'Вы уже добавили регион в который входит этот город. Для добавления данного города требуется сначала удалить регион',
};

export const useCitiesBackendActions = ({ isCreationMode, id }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [{ data, error, loading }] = useAxios<BaseResponse<AdminCitiesAreasResponseDto>>(cityByIdEndpointUrl(id), {
    manual: isCreationMode,
  });

  const deleteCity = useAxios<BaseResponse<AdminCitiesAreasResponseDto>>(deleteCityEndpointUrl(id), {
    manual: true,
  })[1];

  const updateNewData = useAxios(updateCityEndpointUrl(id), { manual: true })[1];

  const saveNewData = useAxios(createCityEndpointUrl(), { manual: true })[1];

  const cityData = useMemo(() => {
    if (isCreationMode) {
      return getCity();
    } else if (data !== undefined) {
      return data?.data;
    }
  }, [isCreationMode, data]);

  const handleDeleteCity = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteCity();
      notification.success({
        message: `Город "${cityData?.name}" удален`,
      });
      navigate(NAVIGATION.cities);
    } catch (error) {
      notification.error({ message: `Извините, произошла ошибка` });
      setIsLoading(false);
    }
  }, [deleteCity, cityData?.name, navigate]);

  const onSave = useCallback(
    async (formFields: AdminCitiesAreasResponseDto, file?: string | Blob | RcFile) => {
      try {
        setIsLoading(true);
        const query = isCreationMode ? saveNewData : updateNewData;

        const queryResult = await query({ data: formFields });

        if (isCreationMode && file) {
          const cityId = queryResult.data.data.id;

          const data = new FormData();
          data.append('image', file as string);

          await axios.patch(`/admin/v1/${ImageEntity.Cities}/${cityId}/image`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }

        notification.success({ message: isCreationMode ? 'Город создан' : 'Город обновлен' });
        navigate(NAVIGATION.cities);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const errMessage = (error as DefaultAxiosError).response?.data.data?.error;
        if (errMessage !== undefined) {
          if (errMessage !== 'Bad Request')
            notification.error({
              message: errorMessagesMapper[errMessage] ?? 'Извините, при сохранении города произошла ошибка',
            });
          else
            notification.error({
              message: 'Извините, при сохранении города произошла ошибка. Скорее всего такого города не существует',
            });
        } else notification.error({ message: 'Извините, при сохранении города произошла ошибка' });
      }
    },
    [isCreationMode, navigate, saveNewData, updateNewData],
  );

  return useMemo(
    () => ({
      info: {
        data,
        error,
        loading,
        isLoading,
        cityData,
      },
      actions: {
        handleDeleteCity: handleDeleteCity,
        onSave,
      },
    }),
    [data, error, handleDeleteCity, isLoading, loading, cityData, onSave],
  );
};
