import { axios } from 'client';
import { Index } from 'client/endpoints';
import { UserContext } from 'contexts/UserContext';
import { TagDto } from 'models';
import { useContext } from 'react';
import { useQuery } from 'react-query';

export const useVideoTags = (enabled = true) => {
  const isAuthorized = typeof useContext(UserContext).user !== 'undefined';

  const fetchData = async () => {
    const { data } = await axios.get(`${Index.videosAdmin}/tags?limit=20`);
    return data.data;
  };

  return useQuery<TagDto[]>(['getVideoTags', isAuthorized], fetchData, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isAuthorized && enabled,
  });
};
